<template>
  <div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import {Notification} from "element-ui";

export default {
  name: 'admin-socket',
  inject:['reload'],
  sockets: {
    HmNoticeMsg(value) {
      if(value){
        Notification({
          title: value.type,
          message:value.message,
          dangerouslyUseHTMLString: true,
          duration: 0,
        });
        this.$curl.get("/hm/homePage/getNoticeList").then((res) => {
          this.saveNoticeMsg(res.data)
        })
      }
    },
  },
  created() {
    this.closeSocketio()
  },
  mounted() {
    setTimeout(() => {
      this.conentSocketio()
    }, 1000)
  },
  beforeDestroy() {
    this.closeSocketio()
  },
  computed:{
    // ...mapGetters(['communityInfo'])
  },
  methods: {
    handleRender() {
      this.reload()  // 刷新页面
    },
    ...mapMutations({
      saveNoticeMsg: 'payment/saveNoticeMsg',
    }),
    conentSocketio() {
      const token = localStorage.getItem("token")
      const uri = this.$config.connection + "/?token=" + token;
      this.$socket.io.uri = uri
      this.$socket.open();
      console.log('链接socket')
      // this.sendMsg();
      this.sockets.subscribe('message', (res) => {
        console.log('新写法获取回调', res)
      })
    },
    // sendMsg() {
    //   this.$socket.emit("message", JSON.stringify({userName: "cxf", passWord: "123456"}));
    // },
    closeSocketio() {
      console.log('关闭链接socket')
      this.$socket.close();
    },
  },
}
</script>

<style lang="scss">
.el-notification__group{
  word-break: break-all
}
.el-notification__content{
  text-align: start;
  strong{
    font-weight: 600;
  }
}
</style>
