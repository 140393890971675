<template>
  <div class="admin_wrapper">
    <!--  侧边栏菜单  -->
    <div class="sidebar">
      <Sidebar/>
    </div>
    <div class="admin_right">
      <!--   头部  应用中心 项目组切换 天气  头像 -->
      <header class="admin_header">
        <Header/>
      </header>
      <!--   主要切换内容  -->
      <main class="admin_main">
        <Main/>
      </main>
      <!--   脚部服务信息   -->
      <footer class="admin_footer">
        <Footer/>
      </footer>
    </div>
  </div>
</template>

<script>
import Sidebar from './admin/sidebar/adminSidebar'
import Header from './admin/header/adminHeader'
import Main from './admin/main/adminMain'
import Footer from "./admin/footer/adminFooter"

export default {
  components: { Footer, Sidebar, Header, Main },
  computed: {

  },
  created() {
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
.admin_wrapper {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  background-color: #F5F6F9;
  overflow: hidden;

  .sidebar {
    width: 233px;
    background-color: #FFFFFF;
    height: 100vh;
    overflow: hidden;
  }

  & > .admin_right {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .admin_header {
    width: 100%;
    background-color: #FFFFFFFF;
  }

  .admin_main {
    overflow: hidden;
    flex: 1;
    margin: 0 40px
  }

  .admin_footer {
    min-height: 15px;
  }

}

@media screen  and (max-width: 1440px) {
  .sidebar {
    width: 160px !important;
  }
}
</style>


